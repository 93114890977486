import React from 'react';

const SignUp = () => {
    return (
        <div>           
            hello
        </div> 
    );
};

export default SignUp;

